import React from "react";
import { Link } from "react-router-dom";

function Photography() {
  return (
    <div>
      <Link to="/">Home</Link>
      <h2>Photography</h2>
    </div>
  )
}

export default Photography;
